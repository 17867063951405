<template>
	<div class="d-flex justify-space-between">
		<div class="d-flex flex-column">
			<h1 class="text-h4 font-weight-bold ma-0 cassie-header-text-color">
				<slot name="header-title" />
			</h1>
			<span class="text-caption">
				<slot name="header-caption" />
			</span>
		</div>
		<v-menu offset-y>
			<template #activator="{ on, attrs }">
				<v-btn
					class="text-body-2 blue-text darken-2 text-capitalize"
					text
					v-bind="attrs"
					v-on="on"
				>
					<span>
						Hello {{ userFullName() }}
					</span>
					<v-icon class="blue--text text--darken-2">
						mdi-chevron-down
					</v-icon>
				</v-btn>
			</template>
			<v-list>
				<v-list-item @click="logOutAndRedirect">
					Log Out
				</v-list-item>
			</v-list>
		</v-menu>
	</div>
</template>

<script>
export default {
	inject: {
		logOutAndRedirect: {
			default: () => () => { }
		},
		userFullName: {
			default: () => ''
		}
	}
}
</script>

<style lang="scss">
.cassie-header-text-color {
	color: var(--global-header-text-color);
}
</style>
