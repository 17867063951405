<template>
	<div
		class="background d-flex flex-column pt-6 overflow-y-hidden"
		style="width: 100%; height: 100%; max-height: 100vh;"
	>
		<div class="px-4 cassie-vertical-md">
			<Header>
				<template #header-title>
					<slot name="header-title" />
				</template>
				<template #header-caption>
					<slot name="header-caption" />
				</template>
			</Header>
			<slot name="header-after" />
			<div />
		</div>
		<div class="px-4">
			<v-divider />
		</div>
		<div
			class="d-flex flex-column overflow-y-auto pa-4 cassie-vertical-md"
		>
			<slot name="content" />
		</div>
		<v-divider
			v-if="$slots['footer']"
			class="mt-auto"
		/>
		<div
			v-if="$slots['footer']"
			class="justify-end px-4 py-6"
		>
			<slot name="footer" />
		</div>
	</div>
</template>

<script>
import Header from '../components/header.vue'
export default {
	components: { Header }
}
</script>
