<template>
	<v-card
		:flat="flat"
		:class="{ 'background': hideBackground }"
	>
		<div class="d-flex flex-row align-center">
			<v-card-title
				v-if="$slots.title"
				class="pb-3"
			>
				<slot name="title" />
			</v-card-title>
			<v-spacer v-if="$slots['title-action'] || collapsible || partiallyCollapsible" />
			<div
				v-if="$slots['title-action'] || collapsible || partiallyCollapsible"
				class="mr-4 my-4 pr-2"
			>
				<slot name="title-action" />
				<Toggle
					v-if="collapsible && !partiallyCollapsible"
					:value.sync="showCardContent"
					label-left
					label="Show"
				/>
				<Toggle
					v-if="partiallyCollapsible && !collapsible"
					:value.sync="showPartialContent"
					label-left
					:toggle-labels="partiallyCollapsibleToggleLabels"
				/>
			</div>
		</div>
		<v-card-subtitle
			v-if="$slots.subtitle"
			class="pt-0"
		>
			<slot name="subtitle" />
		</v-card-subtitle>
		<v-divider v-if="!flat" />
		<v-card-text
			v-show="$slots.body && !collapsible || $slots.body && collapsible && showCardContent"
			:class="{
				'pa-0': embedded
			}"
			class="cassie-vertical-md"
		>
			<slot name="body" />
			<div v-show="showPartialContent">
				<slot
					name="collapsible-content"
				/>
			</div>
		</v-card-text>
	</v-card>
</template>
<script>
import Toggle from './toggle.vue'
export default {
	name: 'section-card',
	components: { Toggle },
	props: {
		embedded: {
			type: Boolean,
			default: false
		},
		collapsible: {
			type: Boolean,
			default: undefined
		},
		collapsed: {
			type: Boolean,
			default: false
		},
		partiallyCollapsible: {
			type: Boolean,
			default: false
		},
		partiallyCollapsed: {
			type: Boolean,
			default: false
		},
		partiallyCollapsibleToggleLabels: {
			type: Array,
			default: () => ['Show', 'Show']
		},
		flat: {
			type: Boolean,
			default: false
		},
		background: {
			type: Boolean,
			default: undefined
		}
	},
	data () {
		return {
			showCardContent: this.collapsed,
			showPartialContent: this.partiallyCollapsed
		}
	},
	computed: {
		hideBackground () {
			return this.background ? false : this.flat
		}
	}
}
</script>
