<template>
	<validation-provider
		tag="div"
	>
		<v-switch
			:input-value="value"
			class="mt-0 cassie-hide-validation"
			:disabled="disabled"
			hide-details
			:label="!labelLeft && label ? label : ''"
			:data-testid="testId"
			@change="updateValue"
		>
			<template
				v-if="labelLeft"
				#prepend
			>
				<span
					class="cassie-switch-enabled"
					:class="value && !retainLabelColour ? 'cassie-switch-enabled' : 'cassie-switch-disabled'"
				>
					{{ statusToggleLabel(value) }}
					<v-tooltip
						v-if="tooltipText"
						slot="append"
						right
					>
						<template #activator="{ on }">
							<v-icon
								light
								class="ml-2"
								v-on="on"
							>
								mdi-information
							</v-icon>
						</template>
						<div v-dompurify-html="tooltipText" />
					</v-tooltip>
				</span>
			</template>
			<template #label>
				<div
					v-if="!labelLeft"
					class="d-flex align-center"
					:class="{ 'cassie-aligned-toggles': alignedToggles }"
				>
					<div v-dompurify-html="label" />
					<v-tooltip
						v-if="tooltipText"
						slot="append"
						right
					>
						<template #activator="{ on }">
							<v-icon
								light
								class="ml-2"
								v-on="on"
							>
								mdi-information
							</v-icon>
						</template>
						<div v-dompurify-html="tooltipText" />
					</v-tooltip>
				</div>
			</template>
		</v-switch>
	</validation-provider>
</template>

<script>
import { VSwitch } from 'vuetify/lib'

export default {
	name: 'toggle',
	extends: VSwitch,
	props: {
		labelLeft: {
			type: Boolean
		},
		label: {
			type: String
		},
		tooltipText: {
			type: String
		},
		alignedToggles: {
			type: Boolean,
			default: false
		},
		value: {
			type: Boolean
		},
		disabled: {
			type: Boolean
		},
		toggleLabels: {
			type: Array,
			default: () => ['Disabled', 'Enabled']
		},
		retainLabelColour: {
			type: Boolean,
			default: false
		},
		testId: {
			type: String,
			default: 'cassie_toggle'
		}
	},
	methods: {
		updateValue (value) {
			this.$emit('update:value', value)
		},
		statusToggleLabel (value) {
			if (this.label) return this.label
			else {
				return value ? this.toggleLabels[1] : this.toggleLabels[0]
			}
		}
	}
}
</script>

<style lang="scss">
	.cassie-switch-enabled {
		color: var(--global-cassie-switch-enabled-color);
		margin-top: 3px;
	}

	.cassie-switch-disabled {
		color: var(--global-cassie-switch-disabled-color);
		margin-top: 3px;
	}

	.cassie-hide-validation {
		.v-messages.v-messages {
			display: none !important;
		}

		.v-input__prepend-outer {
			flex: 1 0 70%;
		}
	}

	.cassie-aligned-toggles {
		width: 100%;
		justify-content: space-between;
	}

</style>
